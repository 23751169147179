@import url("googlefonts.css");

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

body {
    background: #313131;
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 768px) {
    body {
        text-align: center !important;
    }
}

@media (max-width: 1230px) {
    .width {
        width: auto!important;
    }
}

#loginform {
    margin-left: 0;
}

h1, h2, h3, h4 {
    font-weight: bold;
}

.row.no-gutter {
    margin-left: 0;
    margin-right: 0;
}

.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
}

.row.low-gutter {
    margin-left: 0;
    margin-right: 0;
}

.row.low-gutter [class*='col-']:not(:first-child),
.row.low-gutter [class*='col-']:not(:last-child) {
    padding-right: 5px;
    padding-left: 5px;
}

/*
 * Pontos pixel értékek: bootstrap3 breakpointok!
 */
@media (max-width: 1199px) {
    .row.low-gutter .col-md-3.col-xs-12 {
        display: flex;
        justify-content: center;
    }
    div.numbers {
        text-align: center;
    }
}

@media (min-width: 1340px) {
    .services > .container {
        width: 1280px;
    }
}
@media (min-width: 1200px) {
    .szolgaltato-csoportok .col-md-1,
    .szolgaltato-csoportok .col-sm-1,
    .szolgaltato-csoportok .col-lg-1 {
        width: 10%;
    }
}

*:hover {
    -webkit-transition-duration: 0.5s; /* Safari */
    transition-duration: 0.5s;
    transition: all 0.5s;
}

a, a:hover, a:focus, a:visited {
    text-decoration: none;
    outline: none;
}

.login-bg {
    height: auto;
    width: 100%;
    background: #313131;
}

@media (max-width: 768px) {
    .login {
        width: 100% !important;
    }
}

.login {
    margin: 0 auto;
    height: auto;
    width: 768px;
    text-align: center;
    padding: 50px;
    color: #fff;
    display: none;
}

.login .title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}

.login .text {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
}

.login .text a {
    color: #fff;
    text-decoration: underline;
}

.login form {
    margin-bottom: 20px;
}

.login .down {
    height: 80px;
    width: 80px;
}

.login .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 20px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #dddddd;
    border-radius: 50px;
    background: transparent;
    border: 2px solid #fff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.login .form-control:focus {
    border-color: #8cc63f;
    outline: 0;
}

.header {
    height: auto;
    width: 100%;
    background: #f3f3f3;
    padding: 10px;
}

.header .logo {
    height: auto;
    width: auto;
}

@media (max-width: 768px) {
    .header .latok {
        margin-left: 0 !important;
    }

    .menu {
        padding: 5px !important;
    }
}

.header .latok {
    height: 40px;
    width: 40px;
    margin-top: 10px;
    margin-left: 10px;
}

.menu {
    padding: 15px 0 15px 15px;
    margin-top: 5px;
}

.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .menu ul li {
        margin-right: 0 !important;
        padding: 5px !important;
    }
}

.menu ul li {
    display: inline;
    background: transparent;
    width: auto;
    height: auto;
    font-size: 14px;
    color: #035877;
    font-weight: bold;
    margin-right: 10px;
    text-transform: uppercase;
}

.menu ul li:hover {
    color: #066c91;
}

.button {
    height: auto;
    width: auto;
    cursor: pointer;
    border: none;
}

button:focus {
    outline: 0 !important;
}

button:active {
    outline: 0;
    border: none;
}

.header .button {
    margin-top: 10px;
}

button.blue, .button.blue, button.green, .button.green, button.gray, .button .gray {
    padding: 10px 20px;
    min-width: 120px;
    text-align: center;
    border-radius: 50px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    color: #fff;
}

button.blue, .button.blue {
    background: #035877;
}

button.blue:hover, button.blue:focus, .button.blue:hover, .button.blue:focus {
    background: #066c91;
}

button.green, .button.green {
    background: #8cc63f;
}

button.green:hover, button.green:focus, .button.green:hover, .button.green:focus {
    background: #a2df51;
}

.button.green i {
    position: absolute;
    right: 20px;
    top: 22px;
    font-weight: bold;
}

button.big, .button.big {
    font-size: 20px;
}

form button {
    width: 100%;
}

.header-banner {
    height: auto;
    min-height: 700px;
    width: 100%;
}

button.gray, .button .gray {
    background: #484848;
    color: #616161;
}

button.gray:hover, .button.gray:hover, button.gray:focus, .button.gray:focus {
    background: #484848;
}

.alert {
    padding: 20px;
    background: rgb(236,12,12);
	background: linear-gradient(90deg, rgba(236,12,12,1) 0%, rgba(223,0,84,1) 100%);
    color: white;
    opacity: 1;
    transition: opacity 0.6s;
    margin-bottom: 15px;
}

.warning {
	width: 34px;
	height: 40px;
	float: left;
	/*background-image: url("../images/icons/warning.png"); TODO: hiányzó png */
}

.closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.closebtn:hover {
    color: black;
}

.header-banner .text {
	
    font-weight: bold;
    font-size: 48px;
    line-height: 1.0;
    color: #313131;
    padding-top: 18%;
    padding-left: 15%;
    padding-right: 15%;
}

.header-banner .text span {
	font-size:22px;
}

button.green.big {
    margin-top:1em;
}

@media (max-width: 768px) {
    .services {
        padding: 20px !important;
    }

    .header-banner {
        min-height: 350px !important;
    }

    .header-banner .text {
        font-size: 28px !important;
        color: #000000 !important;
        line-height: 1.2!important;
        padding: 20% 5% 30% 5% !important;
        margin-top: 1em;
    }

    .header-banner .text span {
        font-size:18px!important;
    }
}

.services {
    height: auto;
    width: 100%;
    padding: 40px 0px;
    background: #fff;
}

.services .item {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    margin-top: 15px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .services .item img {
        max-height: 100px!important;
    }
}

.services .item img {
    max-height: 125px;
    width: auto;
    margin-bottom: 15px;
}




.attent {
    height: auto;
    width: 100%;
    padding: 40px;
    background: #fff;
    text-align: center;
}

.attent {
}

@media (max-width: 768px) {
    .attent img {
        max-width: 650px!important;
		margin-bottom: 5px;
    }
}
@media (max-width: 420px) {
    .attent img {
        max-width: 395px!important;
		margin-bottom: 5px;
    }
}

.attent img {
    width: auto;
    margin-bottom: 15px;
}


.invoice {
    height: auto;
    width: 100%;
    min-height: 30px;
    background: url('../images/invoice.png');
    background-size: cover;
    padding: 70px;
}

@media (max-width: 768px) {
    .invoice {
        padding: 20px;
    }

    .invoice .text {
        font-size: 24px !important;
        line-height: 30px !important;
        margin-bottom: 30px;
    }
}

.invoice .text {
    font-weight: bold;
    color: #fff;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 30px;
}

.footer {
    height: auto;
    width: 100%;
    background: #eaeaea;
    padding: 30px;
    text-align: center;
}

.footer .links {
    text-align: center;
}

.footer .links a {
    color: #1e1e1e;
    margin-right: 10px;
    font-weight: 500;
}

.footer .copyright {
	margin-top: 20px;
	line-height: 1.4;
}

.footer .isosticker {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
    height: 70px;
}

.footer .social {
    height: 40px;
    width: 83px;
    margin: 30px auto;
    padding: 0;
    display: flex;
    gap: 3px;
}

.footer .social .facebook {
    background: #1a5ea2;
    padding: 10px;
    border-radius: 50%;
    width: 43px;
}

.footer .social .facebook i {
    font-size: 18px;
    color: #fff;
}

.footer .social .twitter {
    background: #23c7da;
    padding: 10px;
    border-radius: 50%;
}

.footer .social .twitter i {
    font-size: 18px;
    color: #fff;
}

.footer .social .google {
    background: #da2323;
    padding: 10px;
    border-radius: 50%;
}

.footer .social .google i {
    font-size: 18px;
    color: #fff;
}

/*
.modal {
    top: 10%;
}
*/

.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid transparent;
    /*border: 1px solid rgba(0, 0, 0, 0);*/
    border-radius: 0;
    outline: none;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0);
    text-align: left !important;
}

iframe {
    width: 100%;
    height: 40vw;
    max-height: 70vh;
}

@media (min-width: 768px) {
    .modal-dialog {
        margin: 30px auto;
    }

    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    }

    .modal-sm {
        width: 300px;
    }
}

@media (min-width: 1000px) {
    .modal-dialog {
        width: 920px;
        margin: 30px auto;
    }
}

.modal-dialog {
    padding: 19px;
}

.modal-content span {
    top: -25px;
    right: -5px;
    color: #fff;
    font-size: 26px;
    position: absolute;
    opacity: 1 !important;
}

.modal-body {
    color: #033777;
    padding: 19px;
}

/*
 * IE 10 & 11 fixek
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* a 20px magas paddingot az IE nem szerette... */
    .login .form-control {
        padding: 6px 12px;
    }
}

/*
 * Saját stílusok
 */
a.lesz-btn-link {
    color: #fff;
    display: block;
}

@media (max-width: 1199px) {
    #join_btn {
        padding: 10px 0px !important;
    }
}

.image-left {
    float: left;
    margin-right: 1.2em;
}

.image-right {
    float: right;
    margin-left: 1.2em;
}