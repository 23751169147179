/* === Montserrat - 300 */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("../fonts/googlefonts/Montserrat-Light.eot");
	src: local("Montserrat Light"),
		local("Montserrat-Light"),
		url("../fonts/googlefonts/Montserrat-Light.eot") format("embedded-opentype"),
		url("../fonts/googlefonts/Montserrat-Light.woff2") format("woff2"),
		url("../fonts/googlefonts/Montserrat-Light.woff") format("woff"),
		url("../fonts/googlefonts/Montserrat-Light.ttf") format("truetype"),
		url("../fonts/googlefonts/Montserrat-Light.svg") format("svg");
}

/* === Montserrat - regular */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../fonts/googlefonts/Montserrat-Regular.eot");
	src: local("Montserrat Regular"),
		local("Montserrat-Regular"),
		url("../fonts/googlefonts/Montserrat-Regular.eot") format("embedded-opentype"),
		url("../fonts/googlefonts/Montserrat-Regular.woff2") format("woff2"),
		url("../fonts/googlefonts/Montserrat-Regular.woff") format("woff"),
		url("../fonts/googlefonts/Montserrat-Regular.ttf") format("truetype"),
		url("../fonts/googlefonts/Montserrat-Regular.svg") format("svg");
}

/* === Montserrat - 500 */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../fonts/googlefonts/Montserrat-Medium.eot");
	src: local("Montserrat Medium"),
		local("Montserrat-Medium"),
		url("../fonts/googlefonts/Montserrat-Medium.eot") format("embedded-opentype"),
		url("../fonts/googlefonts/Montserrat-Medium.woff2") format("woff2"),
		url("../fonts/googlefonts/Montserrat-Medium.woff") format("woff"),
		url("../fonts/googlefonts/Montserrat-Medium.ttf") format("truetype"),
		url("../fonts/googlefonts/Montserrat-Medium.svg") format("svg");
}

/* === Montserrat - 600 */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("../fonts/googlefonts/Montserrat-SemiBold.eot");
	src: local("Montserrat SemiBold"),
		local("Montserrat-SemiBold"),
		url("../fonts/googlefonts/Montserrat-SemiBold.eot") format("embedded-opentype"),
		url("../fonts/googlefonts/Montserrat-SemiBold.woff2") format("woff2"),
		url("../fonts/googlefonts/Montserrat-SemiBold.woff") format("woff"),
		url("../fonts/googlefonts/Montserrat-SemiBold.ttf") format("truetype"),
		url("../fonts/googlefonts/Montserrat-SemiBold.svg") format("svg");
}

/* === Montserrat - 700 */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../fonts/googlefonts/Montserrat-Bold.eot");
	src: local("Montserrat Bold"),
		local("Montserrat-Bold"),
		url("../fonts/googlefonts/Montserrat-Bold.eot") format("embedded-opentype"),
		url("../fonts/googlefonts/Montserrat-Bold.woff2") format("woff2"),
		url("../fonts/googlefonts/Montserrat-Bold.woff") format("woff"),
		url("../fonts/googlefonts/Montserrat-Bold.ttf") format("truetype"),
		url("../fonts/googlefonts/Montserrat-Bold.svg") format("svg");
}
